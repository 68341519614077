import { Callout } from "@radix-ui/themes";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { MapDataCard } from "../../components/MapDataCard/MapDataCard";
import type { BunkaTopicsResponse } from "../../components/TopicsContext/types";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import "./style.css";

const datasetQuery = (id: string) => supabase.from("datasets_with_user").select("*").eq("id", id);

export function MapView(): JSX.Element {
  const { datasetID } = useParams();

  const [apiData, setApiData] = useState<BunkaTopicsResponse>();
  const [error, setError] = useState<string>();
  const [dataset, setDataset] = useState<Tables<"datasets_with_user">>();

  const parseData = useCallback(async (blob: Blob) => {
    try {
      const text = await blob.text(); // Convert Blob to string
      const data = JSON.parse(text); // Parse string as JSON
      setApiData(data);
    } catch (exc) {
      setError("Error parsing topics processing result.");
      console.error(exc);
    }
  }, []);

  useEffect(() => {
    let loading = true;
    if (!datasetID) {
      return () => {
        loading = false;
      };
    }
    datasetQuery(datasetID).then(({ data, error }) => {
      if (loading && data !== null) {
        const datasetResult = data[0];
        if (!datasetResult) {
          setError("Project does not exist.");
          loading = false;
          return;
        }
        setDataset(datasetResult);
        if (!datasetResult.process_topics_result_filepath || !datasetResult.process_topics_result_bucket) {
          setError("Topics processing result not exist.");
          loading = false;
          return;
        }

        if (datasetResult.is_public) {
          axios
            .get(supabase.storage.from(datasetResult.process_topics_result_bucket).getPublicUrl(datasetResult.process_topics_result_filepath).data.publicUrl)
            .then((res) => {
              setApiData(res.data);
            });
        } else {
          supabase.storage
            .from(datasetResult.process_topics_result_bucket)
            .download(datasetResult.process_topics_result_filepath)
            .then(({ data, error }) => {
              if (error) {
                setError("Error downloading topics processing result.");
                loading = false;
                return;
              }

              if (data) {
                parseData(data);
              }
              loading = false;
            });
        }
      }
      if (error) {
        setError("Error fetching projet.");
      }
    });
    return () => {
      setDataset(undefined);
      setApiData(undefined);
      loading = false;
    };
  }, [datasetID, parseData]);

  return (
    <div className="map-view">
      {!!error && (
        <Callout.Root color="red" className="map-error">
          <Callout.Icon>
            <RiErrorWarningLine />
          </Callout.Icon>
          <Callout.Text>{error}</Callout.Text>
        </Callout.Root>
      )}
      {dataset && <MapDataCard dataset={dataset} topics={apiData?.topics} documents={apiData?.docs} />}
    </div>
  );
}
