import { Flex, Progress, Spinner, Text } from "@radix-ui/themes";
import { useSession } from "@supabase/auth-helpers-react";
import { REALTIME_POSTGRES_CHANGES_LISTEN_EVENT, type RealtimePostgresInsertPayload, type RealtimePostgresUpdatePayload } from "@supabase/supabase-js";
import { useEffect, useState } from "react";
import type { Tables } from "../../database.types";
import { supabase } from "../../supabase";
import "./style.css";

const today = new Date();
const WATCHED_TASK_STATUS = ["STARTED", "PENDING"];
const normalisePercentage = (value?: number) => (value ? Math.ceil((value * 100) / 100) : 1);
const datasetQuery = (user_id: string) =>
  supabase
    .from("datasets")
    .select("*")
    .eq("user_id", user_id)
    .is("process_topics_result_filepath", null)
    .neq("backend_processing_task_id", null)
    .or(`backend_processing_task_status.is.null,backend_processing_task_status.in.(${WATCHED_TASK_STATUS.join(",")})`)
    .gte("created_at", new Date(today.setDate(today.getDate() - 1)).toISOString());

export function ImportProgress({ user_id }: { user_id: string }): JSX.Element {
  // Supabase current user session
  const session = useSession();
  const [taskProgress, setTaskProgress] = useState<Tables<"datasets">[]>([]);

  const handleEvents = (payload: RealtimePostgresInsertPayload<Tables<"datasets">> | RealtimePostgresUpdatePayload<Tables<"datasets">>) => {
    console.log(payload);
    // this should not happen with RLS on
    if (payload.new.user_id !== session?.user.id) {
      console.log("wrong user dataset broadcasted");
      return;
    }
    if (
      (payload.eventType === `${REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.UPDATE}` || payload.eventType === `${REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.INSERT}`) &&
      payload.new.backend_processing_task_id &&
      !payload.new.process_topics_result_filepath &&
      (WATCHED_TASK_STATUS.find((value) => value === payload.new.backend_processing_task_status) || payload.new.backend_processing_task_status === null)
    ) {
      const newTaskProgress: Tables<"datasets">[] = taskProgress.filter(
        (task: Partial<Tables<"datasets">>) => task.backend_processing_task_id !== payload.new.backend_processing_task_id,
      );
      newTaskProgress.unshift(payload.new);
      setTaskProgress(newTaskProgress);
    }
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    datasetQuery(user_id).then(({ data, error }) => {
      if (data !== null) {
        if (!data.length) {
          return;
        }
        console.log("init importing datasets data");
        setTaskProgress(data);
        console.log(taskProgress);
      }
      if (error) {
        console.error("Error fetching import task statuses.", error);
      }
    });
    supabase.channel("datasets").on("postgres_changes", { event: "INSERT", schema: "public", table: "datasets" }, handleEvents).subscribe();
    supabase.channel("datasets").on("postgres_changes", { event: "UPDATE", schema: "public", table: "datasets" }, handleEvents).subscribe();
    return () => {
      setTaskProgress([]);
    };
  }, []);

  return (
    <Flex className="import-progress-container">
      {taskProgress?.length ? (
        taskProgress.map((task) => (
          <Flex key={task.backend_processing_task_id} className="import-progress-item">
            <Spinner loading={true} className="import-spinner" />
            <Text className="import-text">{task.name?.slice(0, 20)}</Text>
            <Progress className="import-progress" value={normalisePercentage(task.backend_processing_task_progress ?? undefined)} max={100} variant="soft" />
          </Flex>
        ))
      ) : (
        <></>
      )}
    </Flex>
  );
}
