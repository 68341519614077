import * as Ariakit from "@ariakit/react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Button, Callout, Card, Flex, Heading, IconButton, Inset, Separator, Spinner, Strong, Switch, Text, Theme } from "@radix-ui/themes";
import { useCallback, useEffect, useRef, useState } from "react";
import { RiCloseLargeFill, RiErrorWarningLine, RiQuestionMark } from "react-icons/ri";
import { MapSidebar, MapStyles } from "../../components/MapSidebar/MapSidebar";
import {
  DEFAULT_BLUE,
  createContourLines,
  createLabel,
  createScatterPoints,
  destroyContourLines,
  destroyLabel,
  destroyScatterPoints,
} from "../../components/Maps";
import { createScatterPlot } from "../../components/Maps/scatterPlot";
import type { BunkaTopicsResponse, Document, Topic } from "../../components/TopicsContext/types";
import type { Tables } from "../../database.types";
import { DatasetCard } from "../DatasetCard";
import "./style.css";

interface CreateMapResultType {
  g: d3.Selection<SVGGElement, unknown, null, undefined>;
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  zoom: d3.ZoomBehavior<SVGSVGElement, unknown>;
}

interface Props {
  dataset: Tables<"datasets_with_user">;
  topics?: Topic[];
  docs?: Document[];
}

export function MapPlot({ dataset, topics, docs }: Props): JSX.Element {
  const [selectedTopic, setSelectedTopic] = useState<Topic>();
  const [selectedDocument, setSelectedDocument] = useState<Document>();
  // TODO catch SVG plotting errors
  const [error, setError] = useState<string>();
  const [collapsed, setCollapsed] = useState(true);
  const [mapLoading, setMapLoading] = useState(true);
  const [mapVars, setMapVars] = useState<CreateMapResultType>();
  const [open, setOpen] = useState<boolean>(false);
  const svgRef = useRef<SVGSVGElement | null>(null);

  const createScatterMap = useCallback(
    (apiData: BunkaTopicsResponse) => createScatterPlot(apiData, svgRef, setSelectedDocument, setSelectedTopic, setCollapsed),
    [],
  );

  const [currentMapStyle, _setCurrentMapStyle] = useState<MapStyles>(MapStyles.createScatterMap);
  const createMapOptions: { [key: string]: (apiData: BunkaTopicsResponse) => CreateMapResultType } = {
    createScatterMap,
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <createMapOptions>
  useEffect(() => {
    // still loading
    if ([docs, topics, svgRef.current, open].find((i) => !i)) {
      setMapLoading(true);
      return;
    }
    // Call the function to create the SVG map
    if (topics && docs && svgRef.current) {
      setMapVars(
        createMapOptions[currentMapStyle]({
          docs,
          topics,
          terms: [],
        }),
      );
      setMapLoading(false);
    }
    return () => {
      setSelectedTopic(undefined);
      setMapLoading(true);
    };
  }, [docs, topics, currentMapStyle, open]);

  /**
   * Callback click from the MapSidebar to highlight a point
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <currentMapStyle>
  const handleSelectDocument = useCallback(
    (doc_id: string) => {
      const document = docs?.find((doc) => doc_id === doc.doc_id);

      switch (currentMapStyle) {
        case MapStyles.createScatterMap:
          setSelectedDocument(document);
          break;
        default:
          setSelectedDocument(docs?.find((doc) => doc_id === doc.doc_id));
      }
    },
    [setSelectedDocument, docs],
  );
  /**
   * Handle selected document changes
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: <createLabel, destroyLabel, mapVars>
  useEffect(() => {
    if (mapVars) {
      const { svg, g, xScale, yScale } = mapVars;
      destroyLabel(g);
      // TODO fix blue color from data-color attr
      g.selectAll("circle.document-centroid").style("fill", DEFAULT_BLUE);
      if (!mapLoading && selectedDocument) {
        createLabel(selectedDocument, svg, g, xScale, yScale);
        g.selectAll(`circle.document-centroid[id='${selectedDocument.doc_id}']`).style("fill", "red");
      }
    }
  }, [selectedDocument]);

  const mapDescription =
    "This map is created by embedding documents in a two-dimensional space. Two documents are close to each other if they share similar semantic " +
    "features, such as vocabulary, expressions, and language. The documents are not directly represented on the map; instead, they are grouped into " +
    "clusters. A cluster is a set of documents that share similarities. A cluster is automatically described by a few words that best describes it.";

  return (
    <Theme>
      <Ariakit.Button onClick={() => setOpen(true)} className="MapTriggerButton">
        <DatasetCard showTitle={false} showPreview={true} dataset={dataset} />
      </Ariakit.Button>
      <Ariakit.Dialog open={open} onClose={() => setOpen(false)} className="MapDialogContent">
        <Theme>
          <Flex direction="row" gap="3" mt="1" justify="between" align="center">
            <Heading>
              <Tooltip.Root defaultOpen={false}>
                <Tooltip.Trigger style={{ border: "none", background: "transparent" }}>
                  <Flex gap="2" justify="between" align="center">
                    {" "}
                    <IconButton radius="full" className="scatter-plot-tooltip" variant="soft">
                      <RiQuestionMark />
                    </IconButton>{" "}
                  </Flex>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Theme>
                    <Tooltip.Content className="TooltipContent" sideOffset={5}>
                      <Text>{mapDescription}</Text>
                    </Tooltip.Content>
                  </Theme>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Heading>
            <div className="MapDialogDescription">
              <Strong>{dataset?.name}</Strong>
            </div>
            <Ariakit.DialogDismiss style={{ border: "none", background: "transparent" }}>
              <Button variant="soft">
                <RiCloseLargeFill />
              </Button>
            </Ariakit.DialogDismiss>
          </Flex>
        </Theme>
        <Separator my="3" size="4" />
        <Inset side="x" my="5">
          <Theme>
            <div className="scatter-plot-and-text-container">
              <div className="scatter-plot-container">
                {!!error && (
                  <Callout.Root color="red" className="map-error">
                    <Callout.Icon>
                      <RiErrorWarningLine />
                    </Callout.Icon>
                    <Callout.Text>{error}</Callout.Text>
                  </Callout.Root>
                )}
                <svg ref={svgRef} className="scatter-plot-svg" />
                {mapLoading && !error && <Spinner loading={true} className="scatter-plot-loader" />}
                <Card className="scatter-plot-switch" size="1">
                  <Flex gap="3" align="center">
                    <Switch
                      color="indigo"
                      size="1"
                      onCheckedChange={(checked) => {
                        if (checked && docs && mapVars) {
                          destroyScatterPoints(mapVars.g);
                          createContourLines(docs, mapVars.g, mapVars.xScale, mapVars.yScale);
                        } else if (mapVars) {
                          destroyContourLines(mapVars.g);
                          createScatterPoints(
                            docs,
                            mapVars.g,
                            mapVars.svg,
                            mapVars.xScale,
                            mapVars.yScale,
                            setSelectedTopic,
                            setSelectedDocument,
                            setCollapsed,
                          );
                          mapVars.g.selectAll("text.topic-label").raise();
                          mapVars.g.selectAll("path.convex-hull-contour").raise();
                        }
                      }}
                    />
                    <Text>Topographic map</Text>
                  </Flex>
                </Card>
              </div>
              <MapSidebar
                selectedTopic={selectedTopic}
                selectedDocument={selectedDocument}
                topics={topics}
                documents={docs}
                handleSelectDocument={handleSelectDocument}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              />
            </div>
          </Theme>
        </Inset>
      </Ariakit.Dialog>
    </Theme>
  );
}
