import { Button, Heading } from "@radix-ui/themes";
import { useSession } from "@supabase/auth-helpers-react";
import type React from "react";
import { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { supabase } from "../../supabase";

import "github-markdown-css/github-markdown-light.css";
import "./styles.css";

interface Props {
  datasetId?: number;
}

const DEFAULT_DESCRIPTION = `
# 🌐 Title of our dataset 🇫🇷

## 📊 Dataset Overview

| Attribute | Value |
|-----------|-------|
| **Created** | [Date of creation] |
| **Language** | [Add language dataset] |
| **Model** | [Model name] |

---

## 🔍 Description

[Enter a helpful description here]

### 🎯 Key Features

- **Content**: [You can add some further description]
- **Data Cleaning**: [did you removed outliers ? Clean topics ?]
- **Clustering**: [If you chose Auto, it's HDBScan, otherwise KNN]
- **Minimum Term Count**: [Complete it]

---

## 💡 Potential Applications

[Help the community understand how they could use your dataset !]

---

*Note: Source, Licences, anything can go there !`;

export const MarkdownViewerEditor: React.FC<Props> = ({ datasetId }) => {
  // Supabase current user session
  const session = useSession();
  const userId = session?.user.id;
  const [isEditing, setIsEditing] = useState<boolean>(false); // State to toggle edit mode
  const [markdown, setMarkdown] = useState<string | null>(null);
  const [isOwner, setIsOwner] = useState<boolean>(false);

  const onChange = useCallback((value: string) => {
    setMarkdown(value);
  }, []);

  useEffect(() => {
    if (!datasetId) return;
    // Récupération des données depuis Supabase
    const fetchData = async () => {
      const { data, error } = await supabase.from("datasets").select("description, user_id").eq("id", datasetId).single();

      if (error) {
        console.error("Erreur lors du fetch des données", error);
      } else if (data) {
        setIsOwner(data.user_id === userId);
        if (data.description !== null) {
          setMarkdown(data.description);
        }
      }
    };

    fetchData();
  }, [datasetId, userId]);

  const handleSave = async () => {
    if (!datasetId || !userId || !isOwner) return;
    const { error } = await supabase
      .from("datasets")
      .update({ description: markdown ? markdown : null })
      .eq("id", datasetId);

    if (error) {
      console.error("Erreur lors de la sauvegarde", error);
    } else {
      setMarkdown(markdown);
      setIsEditing(false);
    }
  };
  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className={`markdown-editor ${isEditing ? "editing" : ""}`}>
      {isOwner ? (
        <>
          <Heading>Description</Heading>
          <div className={`editor-container ${isEditing ? "editing" : "viewing"}`}>
            {isEditing ? (
              <>
                <div className="editor-section">
                  <textarea className="textarea" value={markdown ?? DEFAULT_DESCRIPTION} onChange={(e) => onChange(e.target.value)} />
                  <Button className="button" onClick={handleSave}>
                    Save Changes
                  </Button>
                </div>
              </>
            ) : (
              <Button className="button" onClick={toggleEdit}>
                Edit
              </Button>
            )}

            <div className="preview-section">
              <h3>{isEditing ? "Live Preview" : ""}</h3>{" "}
              <div className="markdown-preview markdown-body">
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown ?? DEFAULT_DESCRIPTION}</ReactMarkdown>
              </div>
            </div>
          </div>
        </>
      ) : markdown ? (
        <>
          <Heading>Description</Heading>
          <div className="readonly-preview full-width markdown-body">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown ?? DEFAULT_DESCRIPTION}</ReactMarkdown>
          </div>
        </>
      ) : null}
    </div>
  );
};
