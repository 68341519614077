import { Badge } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import mapPreview from "/static/map-preview.png";
import type { Tables } from "../../database.types";
import "./style.css";

interface Props {
  dataset: Partial<Tables<"datasets_with_user">>;
  to?: string;
  showPreview: boolean;
  showTitle: boolean;
}

export const DatasetCard = ({ dataset, to, showPreview, showTitle }: Props): JSX.Element => {
  return (
    <div
      className="dataset-card"
      style={{
        minHeight: showTitle ? "212px" : undefined,
      }}
    >
      {to ? (
        <Link to={to}>
          <DatasetInside dataset={dataset} showPreview={showPreview} showTitle={showTitle} />
        </Link>
      ) : (
        <DatasetInside dataset={dataset} showPreview={showPreview} showTitle={showTitle} />
      )}
    </div>
  );
};

interface DatasetInsideProps {
  dataset: Partial<Tables<"datasets_with_user">>;
  showPreview: boolean;
  showTitle: boolean;
}

const DatasetInside = ({ showPreview, showTitle, dataset }: DatasetInsideProps): JSX.Element => {
  return (
    <>
      {showPreview && <img className="map-preview" alt="Map" src={mapPreview} />}
      {showTitle ? (
        <div className="labels">
          <div className="top">
            <div className="label">{dataset.name}</div>
            {dataset?.is_public && <Badge color="blue">public</Badge>}
          </div>
          <div className="top">
            <div className="sublabel">{dataset.created_at ? new Date(dataset.created_at).toLocaleDateString() : ""}</div>
            {dataset?.username && (
              <Badge size="1" color="blue" variant="solid">
                {dataset.username}
              </Badge>
            )}
          </div>
        </div>
      ) : (
        <div className="labels">
          <div className="top">
            <div className="label">Bunka scatterplot of "{dataset?.selected_column}"</div>
          </div>
        </div>
      )}
    </>
  );
};
