import { Badge, Box, Button, Code, Container, DropdownMenu, Flex, Heading, Separator, Spinner } from "@radix-ui/themes";
import { useSession } from "@supabase/auth-helpers-react";
import { type ColumnDef, type PaginationState, flexRender, getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSampleDatasetSource, postDeleteDataset } from "../../components/TopicsContext/apiClient";
import type { Tables } from "../../database.types";
import { MapPlot } from "../Maps/MapPlot";
import { MarkdownViewerEditor } from "../MarkdownViewerEditor";
import type { Document, Topic } from "../TopicsContext/types";
import { UserCard } from "../UserCard";
import "./style.css";

export enum MapStyles {
  createScatterMap = "createScatterMap",
}

interface Props {
  dataset: Tables<"datasets_with_user">;
  topics?: Topic[];
  documents?: Document[];
}

const SAMPLE_LINE_NUMBER_COLUMN = "Unnamed: 0";

export function MapDataCard({ dataset, topics, documents }: Props): JSX.Element {
  // Supabase current user session
  const session = useSession();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [sample, setSample] = useState<Record<string, string>[]>([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    let ignore = false;
    async function getSample() {
      setLoading(true);

      if (!dataset.id || dataset.is_public === null) {
        return;
      }
      try {
        const data = await getSampleDatasetSource(dataset.id, session);
        if (!ignore) {
          if (data) {
            setSample(data);
          }
        }
      } catch (exc) {
        console.error(exc);
      } finally {
        setLoading(false);
      }
    }

    getSample();

    return () => {
      ignore = true;
    };
  }, [dataset]);

  // Define columns for the table
  const columns = useMemo<ColumnDef<Record<string, string>>[]>(
    () => [
      ...(Object.keys(sample[0] || {}).includes(SAMPLE_LINE_NUMBER_COLUMN)
        ? [
            {
              accessorKey: SAMPLE_LINE_NUMBER_COLUMN,
              header: "line",
              cell: ({ getValue }) => (
                <Code size="1" variant="ghost">
                  {getValue<string>()}
                </Code>
              ),
            },
          ]
        : []),
      ...(Object.keys(sample[0] || {}).includes(dataset.selected_column ?? "")
        ? [
            {
              accessorKey: dataset.selected_column ?? "",
              header: dataset.selected_column ?? "",
              cell: ({ getValue }) => (
                <Code size="1" variant="ghost">
                  {getValue<string>()}
                </Code>
              ),
            },
          ]
        : []),
      ...Object.keys(sample[0] || {})
        .filter(
          (keyName) =>
            !(
              (Object.keys(sample[0] || {}).includes(dataset.selected_column ?? "") && keyName === dataset.selected_column) ||
              (Object.keys(sample[0] || {}).includes(SAMPLE_LINE_NUMBER_COLUMN) && keyName === SAMPLE_LINE_NUMBER_COLUMN)
            ),
        )
        .map((keyName) => ({
          accessorKey: keyName,
          header: keyName,
          cell: ({ getValue }) => (
            <Code size="1" variant="ghost">
              {getValue<string>()}
            </Code>
          ),
        })),
    ],
    [dataset.selected_column, sample],
  );
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  // Create the table instance
  const table = useReactTable({
    data: sample,
    columns,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <Container className="datacard-container" minWidth="100%">
      <Box py="2" minHeight="100%" minWidth="100%">
        <Flex direction="column" justify="start" maxHeight="100dvh" maxWidth="150dvh">
          <Flex className="datacard-container-section" style={{ flexDirection: "row", justifyContent: "center" }}>
            <div className="map-menu">
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <Button variant="soft" size="4">
                    <Flex gap="2" className="map-menu-label">
                      {dataset?.name || "Options"}
                      {dataset?.is_public && (
                        <Badge color="blue" variant="surface">
                          public
                        </Badge>
                      )}
                      {dataset?.user_id === session?.user.id ? <DropdownMenu.TriggerIcon /> : null}
                    </Flex>
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content>
                  {dataset?.user_id === session?.user.id ? (
                    <>
                      <DropdownMenu.Item disabled>Edit</DropdownMenu.Item>
                      <DropdownMenu.Separator />
                      <DropdownMenu.Item disabled>Add to public datasets</DropdownMenu.Item>
                      <DropdownMenu.Separator />
                      <DropdownMenu.Item
                        color="red"
                        onSelect={async () => {
                          if (dataset?.id) {
                            await postDeleteDataset("topics", dataset.id, session);
                            navigate("/projects");
                          }
                        }}
                      >
                        Delete
                      </DropdownMenu.Item>
                    </>
                  ) : null}
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>
            <div className="map-menu-usercard">
              {dataset?.created_at && <UserCard subLabel={new Date(dataset?.created_at).toLocaleDateString()} username={dataset?.username || undefined} />}
            </div>
          </Flex>
          <Separator style={{ minHeight: "1px" }} my="1" size="4" color="indigo" />
          <Flex className="datacard-container-section" style={{ overflow: "auto" }}>
            <Heading>Dataset preview</Heading>
            {loading && <Spinner loading={true} />}
            {!loading && sample.length ? (
              <>
                <div className="datacard-container-table">
                  <table className="datacard-doc-table">
                    <thead className="datacard-doc-header">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                          {row.getVisibleCells().map((cell) => (
                            <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Separator style={{ minHeight: "1px" }} my="1" size="4" color="gray" />
                <Flex direction="row" gap="3" justify="end">
                  <Button size="3" variant="ghost" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
                    {"<<"}
                  </Button>
                  <Button size="3" variant="ghost" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                    {"<"}
                  </Button>
                  <strong>
                    {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
                  </strong>
                  <Button size="3" variant="ghost" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                    {">"}
                  </Button>
                  <Button size="3" variant="ghost" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
                    {">>"}
                  </Button>
                </Flex>
              </>
            ) : (
              <div className="datacard-container-table" />
            )}
          </Flex>
          <Separator style={{ minHeight: "1px" }} my="1" size="4" color="indigo" />
          <Flex className="datacard-container-section">
            <Heading>Maps</Heading>
            <MapPlot dataset={dataset} topics={topics} docs={documents} />
          </Flex>
          <Separator style={{ minHeight: "1px" }} my="1" size="4" color="indigo" />
          <Flex className="datacard-container-section">{dataset.id ? <MarkdownViewerEditor datasetId={dataset.id} /> : ""}</Flex>
        </Flex>
      </Box>
    </Container>
  );
}
