import { useSessionContext } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { TopicsProvider } from "../../components/TopicsContext/TopicsContext";
import { AuthSignIn } from "../../screens/AuthSignIn";

import "./index.css";
import { Spinner } from "@radix-ui/themes";
import { FallbackComponent } from "../../App";
import { supabase } from "../../supabase";
import { ImportProgress } from "../ImportProgress";

const datasetQuery = (id: string) => supabase.from("datasets").select("is_public").eq("id", id);

export const SessionWrapper = () => {
  const { pathname } = useLocation();
  const { datasetID } = useParams();
  const { isLoading, session, error } = useSessionContext();
  const [passwordResetScreen, showPasswordResetScreen] = useState<boolean>(false);
  const [mapLoading, setMapLoading] = useState(true);
  const [isPublicMap, setIsPublicMap] = useState(false);

  useEffect(() => {
    let loading = true;

    if (pathname.startsWith("/map") && datasetID) {
      datasetQuery(datasetID).then((data) => {
        if (loading && !data?.error && data?.data.length) {
          const datasetResult = data.data[0];
          if (!datasetResult) {
            loading = false;
            return;
          }

          if (datasetResult.is_public) {
            setIsPublicMap(true);
            setMapLoading(false);
            loading = false;
            return;
          }
        }
      });
    } else {
      loading = false;
      setMapLoading(false);
      setIsPublicMap(false);
    }
    return () => {
      loading = false;
      setMapLoading(false);
      setIsPublicMap(false);
    };
  }, [pathname, datasetID]);

  supabase.auth.onAuthStateChange(async (event, _) => {
    if (event === "PASSWORD_RECOVERY") {
      // show screen to update user's password
      showPasswordResetScreen(true);
    }
    if (event === "USER_UPDATED") {
      // show screen to update user's password
      showPasswordResetScreen(false);
    }
  });

  return (
    <TopicsProvider>
      <div className="app-screen">
        <div className="sidebar-container">
          <Sidebar className="sidebar-instance" collapsed={false} />
        </div>
        <div className="app-content">
          {(mapLoading || isLoading) && !error && <Spinner loading={true} />}
          {!mapLoading && (session || isPublicMap) && !isLoading && !error && <Outlet />}
          {!mapLoading && !isLoading && !isPublicMap && (!session || passwordResetScreen) && (
            <AuthSignIn passwordResetScreen={passwordResetScreen} redirectTo="/projects" />
          )}
          {error && <FallbackComponent errorText={error.message} />}
        </div>
        {session && <ImportProgress user_id={session.user.id} />}
      </div>
    </TopicsProvider>
  );
};
